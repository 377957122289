// NOTE: THIS is a shared file between 2sxc-ui/cms and eav-ui
// The original should be maintained in 2sxc-ui/cms and copied to eav-ui

// Only inside a container (div, span, etc.) of the img/html output or the editor
.#{$container-tag} {

  img {
    width: 100%;
    height: auto;
    // This should ensure that the calculated size includes paddings
    box-sizing: border-box;
    
    &.wysiwyg-100 {
      width: 100%;
    }
  }
}
