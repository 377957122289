

.wysiwyg-container {
  &.wysiwyg-debug {
    box-shadow: inset 0 0 15px rgb(234, 0, 255);

    img {
      box-shadow: inset 0 0 15px rgb(0, 255, 0);
      background-image: $debug-background-img;
    }
  }

}
