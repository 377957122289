.wysiwyg-container {
  
  hr.wysiwyg-splitter {
    display: block;
    width: 100%;
    height: 0;
    border-width: 0px!important;
    color: transparent;
    border-color: transparent;
  
    &.wysiwyg-spacer-0 {
      height: 0;
    }

    &.wysiwyg-spacer-s {
      height: $gutter/2;
    }

    &.wysiwyg-spacer-m {
      height: $gutter*1;
      @media screen and (max-width: 768px) {
        height: $gutter*.75;
      }
    }
    
    &.wysiwyg-spacer-l {
      height: $gutter*2;
      @media screen and (max-width: 768px) {
        height: $gutter*1.25;
      }
    }
    
    // &.wysiwyg-spacer-xl {
    //   // background-image: $background;
    //   // height: $grid-gutter-width*2.5;
    //   height: $grid-gutter-width*3;
    //   @media screen and (max-width: 768px) {
    //     // height: $grid-gutter-width*1.75;
    //     height: $grid-gutter-width*1.75;
    //   }
    // }
  }


  $debug-hr-color: #00275388;
  // $border-color: $debug-pattern-color;

  &.wysiwyg-debug {
    hr.wysiwyg-splitter {
      background-color: #ffffff;
      opacity: 0.8;
      background-size: 10px 10px;
  
      border-top: 1px dashed $debug-hr-color!important;
      // border-bottom: 1px dashed $border-color;
      background-image: $debug-background;

      // Make no-height and height-0 splitters visible
      &:not(.wysiwyg-spacer-s, .wysiwyg-spacer-m, .wysiwyg-spacer-l) {
        height: 2px;
      }      
    }
  }
}