//
// Styles for wysiwyg use in the CMS
//
// Concept:
// - the outer container must always have the class `wysiwyg` to apply the styles
// - the inner elements must have the class `wysiwyg-<something>` to apply the styles
// - images will control their width with these classes
//   - wysiwyg-100
//   - wysiwyg-50
//   - wysiwyg-33
//   - wysiwyg-66
//   - wysiwyg-25
//   - wysiwyg-75
// - images will control their float with these classes
//   - wysiwyg-right
//   - wysiwyg-left
//   - wysiwyg-middle (experimental, not final)
// - hr will have things like
//   - wysiwyg-splitter
//   - wysiwyg-spacer-0
//   - wysiwyg-spacer-s
//   - wysiwyg-spacer-m
//   - wysiwyg-spacer-l
//   - wysiwyg-spacer-xl

$gutter: 30px;

:root {
  --wysiwyg-gutter: 30px;
}

// $container-breakpoint-xs: 300;  // Mobile width typical 320px, but container would be smaller
$container-breakpoint-sm: 480px; // Typical grid would be 576px, container would be smaller
// $container-breakpoint-md: 768px;
// $container-breakpoint-lg: 992px;
// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px,
//   xxl: 1400px
// );

$debug-pattern-color: #00275327;
$debug-pattern-bg-color: #ffffff;
$debug-background: repeating-linear-gradient(135deg, $debug-pattern-color 0, $debug-pattern-color 1px, $debug-pattern-bg-color 0, $debug-pattern-bg-color 50%);
$debug-background-img: repeating-linear-gradient(135deg, $debug-pattern-color 0, $debug-pattern-color 1px, $debug-pattern-bg-color 0, $debug-pattern-bg-color 7px);

.wysiwyg-container {
  // container-type: inline-size;
  // container-name: wysiwyg-container;
  container: wysiwyg-container / inline-size;


}

@import './wysiwyg-img';
@import './wysiwyg-splitters';
@import './wysiwyg-debug';